* {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

*:before,
*:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}


#app-wrapper {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
}

#dashboard-window-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

#dashboard-wrapper {
    position: absolute;
    top: 4rem;
    left: 4rem;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: calc(100vw - 4rem - 4rem);
    height: calc(100vh - 5rem - 2px - 4rem);
    padding: 2rem;
}

/* Main content container that contains the graph section
    & insights sidebar. */
.main-content-container {
    position: absolute;
    top: 0.5rem;
    bottom: 2rem;
    left: 5rem;

    display: flex;
    flex-direction: row;
    width: calc(100vw - 5rem - 4rem);
    padding: 1rem;
    margin: 3rem 1rem 1rem 1rem;

    text-align: center;
}

/* Contains the cards with graphs and other populated items. */
.main-content-panel {
    width: 120rem;
    margin: 0.5rem;
    padding: 0.5rem;
}

.compare-content-column {
    width: 26rem;
    height: 15rem;
    margin: 1rem;
    padding: 1rem;
}

.analyze-content-column {
    width: 26rem;
    margin: 1rem;
}

.grahpviz-wrapper {
    min-width: 26rem;
    min-height: 13rem;

    max-width: 30rem;
    max-height: 15rem;

    margin: 0.5rem;
}

.bottom-bar {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 2rem;
    background-color: #99969654;
    border: 1px solid #c9c8c8a6;
    border-radius: 0 0 7.5px 7.5px;
}


#insight-bar {
    display: flex;
    flex-direction: column;

    float: right;

    min-width: 20rem;
    min-height: 35rem;
    padding: 1rem;
    color: #FFFFFF;
    background-color: #3F84D6;
    border: 1px solid #3F84D6;
    border-radius: 7.5px;

    margin-top: 1rem;
}

#compare-insight-bar {
    display: flex;
    flex-direction: column;

    float: right;

    min-width: 20rem;
    min-height: 35rem;
    padding: 1rem;
    color: #FFFFFF;
    background-color: #3F84D6;
    border: 1px solid #3F84D6;
    border-radius: 7.5px;

    margin-top: 1rem;
}

#analysis-selection-bar {
    display: flex;
    flex-direction: column;
    min-width: 20rem;
    min-height: 35rem;
    padding: 1rem;
    color: #FFFFFF;
    background-color: #3F84D6;
    border: 1px solid #3F84D6;
    border-radius: 7.5px;

    margin-top: 1rem;
    justify-content: space-between;
}

#emerging-topic-insight-bar {
    float: right;
    display: flex;
    flex-direction: column;
    width: 40rem;
    min-height: 35rem;
    padding: 1rem;
    color: #FFFFFF;
    background-color: #3F84D6;
    border: 1px solid #3F84D6;
    border-radius: 7.5px;

    margin-top: 1rem;
}

.emerging-topic-insight-strings {
    cursor: pointer;
    margin-left: 0.25rem;
}

#main-graph-insight-section {
    display: flex;
    justify-content: space-between;
}

.compare-insight-reaction {
    float: right;
    margin-bottom: 0.5rem;
    justify-content: right;
    align-items: right;
}

.compare-insight-item {
    display: inline;
}

.draggable-item {
    cursor: move;
}

.graph-close-btn {
    margin-left: 0.5rem;
}

.compare-graph-card-btn {
    cursor: pointer;
    float: right;
}

.selected-table-border {
    background-color: rgb(166, 198, 249);
}

/* Add color to Tab text in Analyze Panel */
#analyze-panel-tabs-tab-controls:not(.active),
#analyze-panel-tabs-tab-insights:not(.active) {
    color: white;
}

/* Sidebar view-toggle buttons styling */
#main-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 4rem;
    height: 100vh;
    background-color: #0A1A2E;
    text-align: center;
}

#sidebar-app-logo-btn {
    margin-top: 2rem;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 2rem;
}

.main-sidebar-btn {
    cursor: pointer;
    color: #FFFFFF;
    font-size: 2rem;

    position: absolute;
    right: 12.5%;
    left: 25%;
}

#sidebar-dashboard-btn {
    top: 36%;
}

#sidebar-compare-btn {
    top: 43%;
}

#sidebar-analyze-btn {
    top: 50%;
}

#sidebar-emerging-topic-btn {
    top: 57%;
}

#sidebar-logout-btn {
    bottom: 4%;
}

.analyze-graph-card {
    height: 100%;
}

.analyze-graph-card-content {
    margin-top: 5%;
}


/* LOGIN FORM CSS */
#login-form {
    background: #FFFFFF;
    padding: 2.5rem 4rem 2.5rem 2.5rem;
    border: 0.25px solid grey;
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
}

#login-form-logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

#login-form-logo-container>img {
    height: 3rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
}

#login-form-org-name {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}

.login-form-btn {
    width: 100%;
    margin: 1rem 0;
}


/* SUBSCRIPTION FORM CSS */
#subscription-form {
    padding: 0 0.25rem 0 0.25rem;
}

.subscription-form-checkbox-item {
    margin: 0.5rem;
}

#subscription-modal-body {
    min-height: 15rem;
}


/* Dahsboard  */
#dashboard-graph {
    height: 100%;
    width: 100%;
    margin: 2rem 0;
}

#dashboard-graph-spinner {
    margin-left: 49%;
}

#dashboard-graph-placeholder-text {
    text-align: center;
}

/* Style for insight string on hover */
.insight-string-text:hover {
    font-weight: 700;
}

/* Style for selected insight string */
.insight-string-selected {
    font-weight: 600;
}

.compare-insight-string {
    box-sizing: border-box;
    margin: 0.5rem;
    text-align: left;
    max-width: 80%;
}

.compare-insight-string label,
.compare-insight-string input {
    cursor: pointer;
}

.analyze-insight-string {
    box-sizing: border-box;
    margin: 0.75rem 0.5rem;
    text-align: left;
    max-width: 90%;
    cursor: default;
}


/* Styles for the Drilldown Popup */
.drilldown-graph-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
}

.util-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
}

.util-scroll {
    overflow: scroll;
}


.wordcloud-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: right;
    min-width: 20rem;
    min-height: 35rem;
    padding: 1rem;
    color: #FFFFFF;
    /* background-color: #dfdfdf;
    border: 1px solid #b2b2b2; */
    background-color: #ebebeb;
    border: 1px solid #b2b2b2;
    border-radius: 7.5px;
    margin-top: 1rem;
}